// Library
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';

// Local
import { DocumentContext } from '../../DocumentContext';
import { saveDocument } from '../../Requests/Document';

const Return = ({ client }) => {
  const context = useContext(DocumentContext);
  const navigate = useNavigate();

  return (
    <Button
      size="small"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: 'none',
        width: '100%',
        textTransform: 'none',
        marginTop: '0px',
      }}
      onClick={async () => {
        if (!context.readOnly) {
          saveDocument(
            client,
            context.documentId,
            context.title,
            context.value,
            context.user,
            context.showNumbering,
            context.autoAppearanceOrder,
            'Note saved'
          );

          navigate('/notes');
        } else {
          navigate('/notes');
        }
      }}
    >
      <KeyboardReturnOutlinedIcon
        fontSize="small"
        sx={{
          marginRight: '8px',
        }}
      />
      <Typography variant="body1">Save note and exit</Typography>
    </Button>
  );
};

export default Return;
