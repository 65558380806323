// Libraries
import React, { useContext, useState } from 'react';
import {
  Drawer,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  darken,
  lighten,
  Box,
} from '@mui/material';

// Local
import { DocumentContext } from '../DocumentContext';
import Documentation from './Components/Documentation';
// import Collaborators from './Components/Collaborators';
// import ManuscriptSettings from './Components/ManuscriptSettings';
// import Header from './Components/Header';
import Return from './Components/Return';
import Feedback from './Components/Feedback';
// import Shortcuts from './Components/Shortcuts';
// import ImportLibrary from './Components/ImportLibrary';
import ExportLibrary from './Components/ExportLibrary';
import ExportManuscript from './Components/ExportManuscript';
// import InfoSquare from './Components/InfoSquare';
import AddPaperToLibrary from './Components/AddPaperToLibrary';
import ZoteroCollection from './Components/ZoteroCollection';
import Theme from './Components/Theme'; // Import the Theme component
import AccountSettings from './Components/AccountSettings'; // Import the AccountSettings component

const Panel = ({ client }) => {
  const context = useContext(DocumentContext);
  const [backdrop, setBackdrop] = useState(false);
  const [backdropText, setBackdropText] = useState('');

  return (
    <Drawer
      anchor="left"
      open={context.togglePanel}
      onClose={() => context.updateContext('togglePanel', false)}
      PaperProps={{
        sx: {
          overflowY: 'auto',
          overflowX: 'hidden',
          width: 305,
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? darken(theme.palette.background.paper, 0.02)
              : lighten(theme.palette.background.paper, 0.02),
        },
      }}
    >
      <Backdrop
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'cornflowerblue',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={backdrop}
        onClick={() => setBackdrop(false)}
      >
        <CircularProgress
          color="inherit"
          sx={{
            marginBottom: '10px',
          }}
        />
        <Typography
          sx={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {backdropText}
        </Typography>
      </Backdrop>

      <Stack
        direction={'column'}
        sx={{
          height: '100%',
          justifyContent: 'flex-start',
          padding: '10px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* Save note and exit */}
        <Return client={client} />
        <ExportManuscript
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        />
        <ExportLibrary
          client={client}
          setBackdrop={setBackdrop}
          setBackdropText={setBackdropText}
        />
        <AddPaperToLibrary setBackdrop={setBackdrop} setBackdropText={setBackdropText} />
        {context.user.zoteroCollectionName && <ZoteroCollection />}

        <Box sx={{ flexGrow: 1 }} />

        <Theme />
        <AccountSettings />
        <Documentation />
        <Feedback />
      </Stack>
    </Drawer>
  );
};

export default Panel;
