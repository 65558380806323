import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const ZoteroIcon = () => {
  return (
    <SvgIcon
      viewBox="0 0 14px 14px"
      sx={{ transform: 'translate(0px, 3px)' }}
    >
      <polygon
        stroke="rgb(188, 57, 60)"
        strokeWidth="1"
        fill="rgb(188, 57, 60)"
        points="13.863 2.73 13.027 1 2.137 1 2.137 3.8 2.137 3.921 8.822 3.921 1.289 13.233 2.137 15 13.863 15 13.863 12.142 13.863 12.021 6.448 12.021 13.863 2.73"
      />
    </SvgIcon>
  );
};

export default ZoteroIcon;
