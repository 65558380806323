// Libraries
import React, { ReactNode } from 'react';
import { Modal, Box, Typography } from '@mui/material';

/**
 * The standardized modal to be used across the application
 *
 * Author @Natedeploys
 * @date 11/02/2024 - 14:09:10
 *
 * @param {object} children - The child elements that modal will display
 * @returns {component}
 */

interface GlobalModalProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  heading: string;
  subheading?: string;
  children: ReactNode; // This ensures children are properly typed
  width?: number;
}

const GlobalModal: React.FC<GlobalModalProps> =({ children, isOpen, setOpen, heading, subheading, width=400 }) => (
  <Modal
    open={isOpen}
    onClose={() => {
      setOpen(false);
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    aria-label='global-modal'
  >
    <>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {width},
          bgcolor: 'background.paper',
          border: 'none',
          outline: 'none',
          color: 'text.primary',
          boxShadow: 24,
        }}
      >
        <Box
          sx={{
            borderBottom: (theme) =>
              theme.palette.mode === 'dark'
                ? '1px solid #333333'
                : '1px solid #d4d4d4',
            paddingBottom: '10px',
          }}
        >
          <Typography
            id="global-modal-heading"
            variant="h6"
            sx={{
              paddingTop: 2,
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            {heading}
          </Typography>
          <Typography
            id="global-modal-subheading"
            sx={{
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            {subheading}
          </Typography>
        </Box>
        <Box
          sx={{
            maxHeight: '500px',
            overflowY: 'auto',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 2,
            paddingRight: 2,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  </Modal>
);

export default GlobalModal;
