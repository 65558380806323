// Library
import React, { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Local
import { GENERATE_BIBTEX } from '../../Queries/nlp';
import { DocumentContext } from '../../DocumentContext';
import { downloadBibtex } from '../../Utilities/exporting';
import { notify } from '../../../Common/notify';

const ExportLibrary = ({ setBackdrop, setBackdropText }) => {
  const context = useContext(DocumentContext);

  const [generateBibtex] = useLazyQuery(GENERATE_BIBTEX);

  return (
    <Button
      size="small"
      sx={{
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: 'none',
        width: '100%',
        textTransform: 'none',
      }}
      onClick={async () => {
        setBackdrop(true);
        setBackdropText('Exporting your library, please wait...');

        const results = context.bookmarks;

        // Create bookmarks map
        const bookmarks = {};
        const paperList = [];

        for (let i = 0; i < results.length; i++) {
          const ref = results[i];
          bookmarks[ref.id_value] = { ...ref, checked: false };
        }

        for (const key in bookmarks) {
          if (Object.prototype.hasOwnProperty.call(bookmarks, key)) {
            paperList.push({
              collection: bookmarks[key]['id_collection'],
              id_field: bookmarks[key]['id_field'],
              id_value: bookmarks[key]['id_value'],
              id_type: bookmarks[key]['id_type'],
            });
          }
        }

        generateBibtex({
          fetchPolicy: 'network-only',
          variables: {
            paper_list: paperList,
          },
          onCompleted: ({ generateBibtex }) => {
            if (generateBibtex.status === 'success') {
              const text = generateBibtex.response
                .map((item) => item)
                .join('\n');

              downloadBibtex(text);
              setBackdrop(false);
            } else {
              notify('Could not export library, try again later');
              setBackdrop(false);
            }
          },
        });
      }}
    >
      <FileDownloadOutlinedIcon
        fontSize="small"
        sx={{
          marginRight: '8px',
        }}
      />
      <Typography variant="body1">Export library as .BIB</Typography>
    </Button>
  );
};

export default ExportLibrary;
