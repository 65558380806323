// Libraries
import React, { useState, useEffect } from 'react';
import { 
	TextField, 
	Stack, 
	ListItemIcon, 
	MenuItem, 
	Typography,
	IconButton,
	Select,
	FormControl,
	InputLabel,
	Box,
	RadioGroup,
	FormControlLabel,
	Radio,
  Autocomplete,
  Slider
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

// Local
import EditableChip from './EditableChip';
import { filterByOptions, conditionOptions } from './KeywordFilteringScripts'


const ValueTextField = ({filterValues, setfilterValues}) => {
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState('');

  const options = []

  const handleInputChange = async (query) => {
    setAutoCompleteInputValue(query);
  };

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      sx={{ width: "50%"}}
      options={options}
      inputValue={autoCompleteInputValue}
      onInputChange={(_, newInputValue) => {
        handleInputChange(newInputValue);
      }}
      freeSolo
      value={filterValues}
      onChange={(_, newValue) => {
        setfilterValues(newValue)
      }}
      renderTags={(value, getTagProps) => (
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'hidden',
              flexWrap: 'wrap',
              display: 'flex',
            }}
          >
            {value.map((option, index) => (
              <React.Fragment key={index}>
                {index === 0 ? (<></>) :
                (<Typography variant="body2" sx={{position: "relative", top: "8px", fontSize: "10px"}}>
                  OR
                </Typography>)
                }
                <EditableChip
                  key={option}
                  option={option}
                  index={index}
                  getTagProps={getTagProps}
                  setAutoCompleteInputValue={setAutoCompleteInputValue}
                  useKeywordsContext={false}
                  customContext={filterValues}
                  setCustomContext={setfilterValues}
                />
              </React.Fragment>
            ))}
          </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Values"
          InputLabelProps={{ shrink: true }}
          placeholder="Value OR Values"
          variant="outlined"
        />
      )}
    />
  );
};

const RadioGroupSelector = ({handleChangeValue, value}) => {
  return (
    <FormControl sx={{ width: "50%"}} >
      <RadioGroup
        row
        value={value}
        onChange={(event) => handleChangeValue(event.target.value)}
        sx={{ justifyContent: 'center', display: 'flex' }}
      >
        <FormControlLabel value="true" control={<Radio />} label="True" />
        <FormControlLabel value="false" control={<Radio />} label="False" />
      </RadioGroup>
    </FormControl>
  )
}

const RangeSlider = ({ handleChangeValue, value }) => {
  const handleSliderChange = (_, newValue) => {
    handleChangeValue(newValue);
  };

  return (
    <Box sx={{ width: '50%', justifyContent: 'center', display: 'flex' }}>
      <Slider
        sx={{ width: '85%' }}
        min={1940}
        max={2025}
        step={1}
        valueLabelDisplay="auto"
        value={value}
        onChange={handleSliderChange}
      />
    </Box>
  );
};

const ConditionSelector = ({handleChangeCondition, condition, conditionOptions}) => {

  return (
    <FormControl 
      sx={{ width: "20%"}}
    >
      <InputLabel 
        id={"condition-select-label"}
      >
        Condition
      </InputLabel>
      <Select
        sx={{maxHeight: 56}}
        labelId={"condition-select-label"}
        id={"condition-select"}
        value={condition}
        label="Condition"
        onChange={(event) => handleChangeCondition(event.target.value)}
      >
        {conditionOptions.map((option) => (
          <MenuItem key={option.id} value={option.label}>
            <ListItemIcon>{option.label}</ListItemIcon>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const KeywordFiltering = ({ index, filter, handleFilterChange }) => {

  const handleChangeFilterBy = (value) => {     
    handleFilterChange(index, 'filterBy', value.label);
  };
	const handleChangeCondition = (value) => {
    handleFilterChange(index, 'condition', value);
  };
	const handleChangeValue = (value) => {
    handleFilterChange(index, 'value', value);
  };


  return (
    <>
      <Stack 
        direction="row" 
        spacing={2}
        sx={{
          marginBottom: '10px',
          alignItems: 'center'
        }}
      >
        {[filterByOptions[6].label, filterByOptions[7].label].includes(filter.filterBy) ? (
          <RadioGroupSelector
            handleChangeValue={handleChangeValue}
            value={filter.value}
          />
        ) : filter.filterBy ===filterByOptions[1].label ? (
          <>
            <RangeSlider
            handleChangeValue={handleChangeValue}
              value={filter.value}
            />
            <ConditionSelector
              handleChangeCondition={handleChangeCondition}
              condition={filter.condition}
              conditionOptions={conditionOptions}
            />
          </>
        ) : (
          <>
            <ValueTextField 
              filterValues={filter.value}
              setfilterValues={handleChangeValue}
            />
            <ConditionSelector
              handleChangeCondition={handleChangeCondition}
              condition={filter.condition}
              conditionOptions={conditionOptions}
            />
          </>
        )}
        <FormControl 
          sx={{
            width: [filterByOptions[6].label, filterByOptions[7].label].includes(filter.filterBy) ? '50%' : '30%'
          }}
        >
          <InputLabel>Filter By</InputLabel>
          <Select
            value={filter.filterBy}
            onChange={(event) => {
							const selectedOption = filterByOptions.find(option => option.label === event.target.value);
							handleChangeFilterBy(selectedOption);
							if ([filterByOptions[6].label, filterByOptions[7].label].includes(selectedOption.label)) {
								handleChangeValue('true'); // Set default value to true
							} else if (selectedOption.label === filterByOptions[1].label) {
                handleChangeValue([2000, 2020]); // Set default range values
              } else {
                handleChangeValue([]);
              }
						}}
            label="Filter By"
          >
            {filterByOptions.map((option) => (
              <MenuItem key={option.id} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton 
          aria-label="delete" 
          size="small"
          sx={{
            position: "relative",
            top: "-10px",
            right: "10px",
          }}
          onClick={() => handleFilterChange(index)}
        >
          <ClearIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    </>
  );
};

const KeywordFilteringList = ({filters, setFilters}) => {
  
  // if rendering the KeywordFilters and there is not a single filter to display just create an empty one
  useEffect(() => {
    if (filters.length === 0) {
      setFilters([{ filterBy: '', condition: conditionOptions[0].label, value: [] }])
    } 
  }, []);


  const addFilter = () => {
    setFilters([...filters, { filterBy: '', condition: 'IN', value: [] }]);
  };

  const handleFilterChange = (index, name, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      if (name) {
        updatedFilters[index] = { ...updatedFilters[index], [name]: value };
      } else {
        updatedFilters.splice(index, 1);
      }
      return updatedFilters;
    });
  };


  return (
    <>
      <Stack 
        direction="column" 
        spacing={1}
        sx={{
          marginBottom: '5px',
        }}
      >
        {filters.map((filter, index) => (
          <KeywordFiltering 
            key={index} 
            index={index} 
            filter={filter} 
            handleFilterChange={handleFilterChange} 
          />
        ))}
      </Stack>
      <Typography
        sx={{
          cursor: 'pointer',
          display: 'inline-block', 
          fontSize: '0.875rem', 
          '&:hover': {
            color: 'primary.main',
          },
        }}
        onClick={addFilter}
      >
        AND
      </Typography>
    </>
  );
};

export default KeywordFilteringList;
