// Libraries
import React, { useContext, useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';

// Local
import { DocumentContext } from '../../Document/DocumentContext';
import { notify } from '../../Common/notify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ZOTERO_GET_COLLECTIONS, ZOTERO_SET_COLLECTION } from '../../Queries';

const CollectionList = ({ setModal }) => {
  const context = useContext(DocumentContext);
  const [loading, setLoading] = useState(true);
  const [zoteroCollections, setZoteroCollections] = useState([]);
  const [mutationLoading, setMutationLoading] = useState(false); // Track mutation loading state
  const [error, setError] = useState(null);

  // Lazy query to fetch collections
  const [getCollections] = useLazyQuery(ZOTERO_GET_COLLECTIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setZoteroCollections(data.zoteroGetCollections.response);
      setLoading(false);
    },
    onError: (error) => {
      notify(`Failed to fetch Zotero collections: ${error.message}`);
      setError(error.message);
      setLoading(false);
    },
  });

  const [setCollection] = useMutation(ZOTERO_SET_COLLECTION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setMutationLoading(false); 
      setModal(false);
      const selectedCollection = data.zoteroSetCollection.zoteroCollectionName
      // update context
      const newUser = {
        ...context.user,
        zoteroCollectionName: selectedCollection,
      };

      // Use the context's updateContext method to update the user
      context.updateContext('user', newUser);
      notify(`Selected ${selectedCollection} as default collection. Now you can add papers to Zotero.`);
    },
    onError: (error) => {
      setMutationLoading(false);
      notify(`Failed to set Zotero collection: ${error.message}`);
    },
  });

  useEffect(() => {
    setLoading(true);
    getCollections();
  }, [getCollections]);

  const handleSelect = (zoteroCollectionName) => {
    setMutationLoading(true);
    setCollection({
      variables: {
        zoteroCollectionName: zoteroCollectionName,
      },
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>Loading Zotero collections...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography>Error loading collections: {error}</Typography>;
  }

  return !zoteroCollections || zoteroCollections.length === 0 ? (
    <Typography>No collections found, please create a Zotero collection first</Typography>
  ) : (
    <List>
      {zoteroCollections.map((collection, index) => {
        const isSelected = context.user.zoteroCollectionName === collection;

        return (
          <ListItem key={index} divider disablePadding>
            <ListItemButton selected={isSelected} onClick={() => handleSelect(collection)}>
              <ListItemText primary={collection} />
              {mutationLoading && isSelected && (
                <CircularProgress size={20} sx={{ ml: 2 }} /> // Show a small loading spinner next to the selected item
              )}
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default CollectionList;
