// Libraries
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

// Local
import { notify } from '../../Common/notify';
import { zoteroDisconnect, zoteroRedirect } from '../Requests';
import GlobalModal from '../../Common/GlobalModal';
import CollectionList from './CollectionList';

const Zotero = ({ client, user, small = false, selectCollection = false }) => {
  const [connected, setConnected] = useState(false);
  const [modal, setModal] = useState(false);

  const requestZoteroAuth = async () => {
    const { status, response, message } = await zoteroRedirect(client);

    if (status === 'success') {
      notify(message);
      localStorage.setItem('zoteroSessionKey', response.sessionKey);

      // Get Zotero authorization
      window.location.replace(response.redirectUrl);
    } else {
      notify(message);
    }
  };

  const disconnect = async () => {
    const { status, message } = await zoteroDisconnect(client);

    if (status === 'success') {
      notify(message);
      setConnected(false);
    } else {
      notify(message);
    }
  };

  useEffect(() => {
    if (user.zoteroUserID !== '') {
      setConnected(true);
    } else {
      setConnected(false);
    }
  }, [user.zoteroUserID]);

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px',
        marginBottom: small ? '0px' : '20px',
        boxSizing: 'border-box',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          display: small && 'none',
          marginBottom: '10px',
        }}
      >
        Zotero
      </Typography>
      <Stack
        direction="column"
        sx={{
          display: small && 'none',
          marginBottom: '10px',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            marginBottom: '10px',
          }}
        >
          Endoc automatically creates a dedicated folder with your citations and
          references in the Zotero library when exporting
        </Typography>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          marginBottom: '10px',
        }}
      >
        {connected ? (
          <>
            <Alert severity="success">Connected to Zotero</Alert>
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: small ? 'none' : 'uppercase',
                }}
                onClick={disconnect}
              >
                Disconnect
              </Button>
              {selectCollection && (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: small ? 'none' : 'uppercase',
                    }}
                    onClick={() => setModal(true)}
                  >
                    Select Collection
                  </Button>
                  <GlobalModal
                    isOpen={modal}
                    setOpen={setModal}
                    heading={'Zotero Collections'}
                    subheading={'Select Zotero collection to upload paper'}
                    aria-label={'global-modal'}
                  >
                    <CollectionList setModal={setModal} />
                  </GlobalModal>
                </>
              )}
            </Stack>
          </>
        ) : (
          <Button
            variant="outlined"
            sx={{
              textTransform: small && 'none',
            }}
            onClick={() => {
              requestZoteroAuth();
            }}
          >
            Connect to Zotero
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Zotero;
