// Library
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

const AccountSettings = () => {
  const navigate = useNavigate();

  return (
    <Button
      size="small"
      onClick={() => navigate("/account")}
      sx={{
        alignItems: "center",
        justifyContent: "flex-start",
        border: "none",
        width: "100%",
        textTransform: "none",
        marginTop: "0px",
      }}
    >
      <AccountCircleOutlinedIcon
        fontSize="small"
        sx={{
          marginRight: "8px",
        }}
      />
      <Typography variant="body1">
        Account Settings
      </Typography>
    </Button>
  );
};

export default AccountSettings;
