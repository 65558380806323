// Libraries
import React from 'react';
import { Stack, Typography } from '@mui/material';

const Hero = () => (
  <Stack
    direction="row"
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      paddingTop: '6vw',
      paddingLeft: '5vw',
      paddingRight: '5vw',
      boxSizing: 'border-box',
      height: 'auto',
      bgcolor: 'background.paper',
    }}
  >
    <Stack
      direction="column"
      data-test="component-content"
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'left',
        height: '100%',
        boxSizing: 'border-box',
        width: { xs: '100%', md: '100%' },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: 'center',
          marginBottom: '3vw',
          fontWeight: 'bold',
          background: '#265ed5',
          width: { xs: '100%', md: '80%' },
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          display: 'inline-block',
        }}
      >
        Discover papers with smart tools
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'center',
          width: { xs: '100%', md: '45%' },
          marginBottom: '1vw',
        }}
      >
        FIND the papers most relevant to your notes. Save time on
        reading with automatically generated summaries. Focus on the research
        tasks that matter. Developed for researchers, by researchers.
      </Typography>
    </Stack>
  </Stack>
);

export default Hero;
