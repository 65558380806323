

export const filterByOptions = [
	{ label: 'Publication Year', id:0},
  { label: 'Publication Year Range', id:1},
	{ label: 'Author Name', id:2}, 
	{ label: 'Title', id:3},
	{ label: 'Venue', id:4},
	{ label: 'DOI', id:5},
	{ label: 'Has Abstract Parsed', id:6},
	{ label: 'Has Fullbody Parsed', id:7},
];

export const conditionOptions = [
	{  id:0, label: 'IN' },
	{  id:1, label: 'NOT IN' },
];

export const filterParser = (filter) => {
  let condition = ""
  if (filter.condition === conditionOptions[0].label) {
    condition = ""
  } else {
    condition = "!"
  }
  if (filter.filterBy === filterByOptions[0].label) {
    if (filter.value.length === 0) return ""
    return `${condition}PublicationDate.Year:${filter.value.join('|')}`

  } else if (filter.filterBy === filterByOptions[1].label) {
    const minYear = Math.min(...filter.value.map(v => parseInt(v)));
    const maxYear = Math.max(...filter.value.map(v => parseInt(v)));
    return `${condition}PublicationDate.Year:${minYear}..${maxYear}`

  } else if (filter.filterBy === filterByOptions[2].label) {
    if (filter.value.length === 0) return ""
    return `${condition}Author.FullName:${filter.value.join('|')}` 

  } else if (filter.filterBy === filterByOptions[3].label) {
    if (filter.value.length === 0) return ""
    return `${condition}Title:${filter.value.join('|')}` 

  } else if (filter.filterBy === filterByOptions[4].label) {
    if (filter.value.length === 0) return ""
    return `${condition}Venue:${filter.value.join('|')}` 

  } else if (filter.filterBy === filterByOptions[5].label) {
    if (filter.value.length === 0) return ""
    return `${condition}DOI:${filter.value.join('|')}` 

  } else if (filter.filterBy === filterByOptions[6].label) {
    if (filter.value === 'false') {
      condition = "!"
    } else {
      condition = ""
    }
    return `${condition}AvailableField:Content.Abstract_Parsed`

  } else if (filter.filterBy === filterByOptions[7].label) {
    if (filter.value === 'false') {
      condition = "!"
    } else {
      condition = ""
    }
    return `${condition}AvailableField:Content.Fullbody_Parsed`

  } else {
    return ''
  }
} 


export const isFilterValid = (filter) => {
  // Define the required fields
  const requiredFields = ["condition", "filterBy", "value"];
  const allowedFilterBys = filterByOptions.map(option => option.label);

  // Check if all required fields are present and not empty
  for (const field of requiredFields) {
    if (!Object.prototype.hasOwnProperty.call(filter, field) || filter[field] === undefined || filter[field] === null) {
      return false;
    }
  }

  // Check if filterBy has a valid value
  if (!allowedFilterBys.includes(filter.filterBy)) {
    return false;
  }

  // Check for valid value types depending on the filterBy option
  if (filterByOptions.slice(0,6).map(o => o.label).includes(filter.filterBy)) {
    if (!Array.isArray(filter.value) || filter.value.length === 0) {
      return false;
    }
  } else if (filterByOptions.slice(6,8).map(o => o.label).includes(filter.filterBy)) {
    if (typeof filter.value !== "string" || (filter.value !== "true" && filter.value !== "false")) {
      return false;
    }
  }

  return true;
};


// Filters in the Publication Year Range have [Int] values which is not consistent of the [String] type
// use the encodeFilter and the decode to change that
export const encodeFilter = (filter) => {
  // If Publication Year Range
  if (filterByOptions.slice(1,2).map(o => o.label).includes(filter.filterBy)) {
    const minYear = `${filter.value[0]}`
    const maxYear = `${filter.value[1]}`
    return {filterBy: filter.filterBy, condition: filter.condition, value: [minYear, maxYear]}
  }
  // If  Has Abstract Parsed or Has Fullbody Parsed
  else if (filterByOptions.slice(6,8).map(o => o.label).includes(filter.filterBy)) {
    return {filterBy: filter.filterBy, condition: filter.condition, value: [filter.value]}
  }

  return filter;
};

export const decodeFilter = (filter) => {
  // If Publication Year Range
  if (filterByOptions.slice(1,2).map(o => o.label).includes(filter.filterBy)) {
    const minYear = parseInt(filter.value[0])
    const maxYear = parseInt(filter.value[1])
    return {filterBy: filter.filterBy, condition: filter.condition, value: [minYear, maxYear]}
  }
  // If  Has Abstract Parsed or Has Fullbody Parsed
  else if (filterByOptions.slice(6,8).map(o => o.label).includes(filter.filterBy)) {
    return {filterBy: filter.filterBy, condition: filter.condition, value: filter.value[0]}
  }

  return filter;
};