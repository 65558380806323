// Library
import React, { useContext } from "react";
import { Typography, Stack, Tooltip, Switch, useTheme } from "@mui/material";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

// Local
import { ColorModeContext } from "../../../DarkMode";

const Theme = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isDarkMode = theme.palette.mode === "dark";
  const modeLabel = isDarkMode ? "Disable Dark Mode" : "Enable Dark Mode";

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        justifyContent: "flex-start",
        border: "none",
        width: "100%",
        textTransform: "none",
        marginTop: "0px",
        marginLeft: "4px",
      }}
    >
      {/* Dark Mode Icon */}
      <DarkModeOutlinedIcon fontSize="small" sx={{ marginRight: "8px" }} />

      {/* Theme Label */}
      <Typography variant="body1" sx={{ marginRight: "8px" }}>
        Dark Mode
      </Typography>

      {/* Dark Mode Toggle */}
      <Tooltip title={modeLabel}>
        <Switch
          checked={isDarkMode}
          onChange={() => colorMode.toggleColorMode()}
          color="default"
          inputProps={{ "aria-label": modeLabel }}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "cornflowerblue",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "cornflowerblue",
            },
          }}
        />
      </Tooltip>
    </Stack>
  );
};

export default Theme;
