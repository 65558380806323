// Library
import React, { useContext, useState } from 'react';
import { Button, Typography } from '@mui/material';
import ZoteroIcon from '../../../Zotero/ZoteroIcon';

// Local
import { DocumentContext } from '../../DocumentContext';
import GlobalModal from '../../../Common/GlobalModal';
import CollectionList from '../../../Account/Zotero/CollectionList';

const ZoteroCollection = () => {
  const context = useContext(DocumentContext);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        size="small"
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
          marginTop: '0px',
          marginLeft: '3px',
        }}
        onClick={() => setModalOpen(true)} // Enable button and set modal open on click
      >
        <ZoteroIcon
          fontSize="small"
          sx={{
            marginRight: '10px',
          }}
        />
        <Typography variant="body1">
          Collection: {context.user.zoteroCollectionName || 'Select Collection'}
        </Typography>
      </Button>

      {/* Modal for selecting collection */}
      <GlobalModal
        isOpen={modalOpen}
        setOpen={setModalOpen}
        heading="Zotero Collections"
        subheading="Select Zotero collection to upload paper"
        aria-label="global-modal"
      >
        <CollectionList setModal={setModalOpen} />
      </GlobalModal>
    </>
  );
};

export default ZoteroCollection;
