// Libraries
import React, { useContext } from 'react';
import { Box } from '@mui/material';

// Local
import { DocumentContext } from '../DocumentContext';
import Menu from './Menu';
import Search from './Search';
import Viewer from './View';

const Explorer = ({ client, user }) => {
  const context = useContext(DocumentContext);

  return (
    <Box
      sx={{
        height: 'inherit',
        overflow: 'hidden',
        width: context.view.explorer ? '100%' : '45%',
        scrollPaddingTop: '1000px',
      }}
    >
      <Menu />
      <Search client={client} user={user} />
      <Viewer client={client} />
    </Box>
  );
};

export default Explorer;
