// Library
import React, { useContext, useRef, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Local
import { PAGINATED_SEARCH } from '../../Queries/nlp';
import { IMPORT_PDF, ADD_BOOKMARK_FROM_METADATA } from '../../Queries/document';
import { DocumentContext } from '../../DocumentContext';
import { ModularContext } from '../../../Modular/ModularContext';
import GlobalModal from '../../../Common/GlobalModal';
import { notify } from '../../../Common/notify';
import { PaperForm } from '../../../Common/PaperForm';
import { bytesToMegaBytes } from '../../Utilities/bytesToMegabytes';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const AddPaperToLibrary = ({ setBackdrop, setBackdropText, modular = false }) => {
  const contextSource = modular ? ModularContext : DocumentContext;
  const context = useContext(contextSource);
  const importLibraryPDF = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [modal, setModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const [addBookmarkFromMetadata] = useMutation(ADD_BOOKMARK_FROM_METADATA, {
    fetchPolicy: 'network-only',
  });

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const [importPDF] = useMutation(IMPORT_PDF);

  const importFiles = async (base64list) => {
    setBackdrop(true);

    importPDF({
      fetchPolicy: 'network-only',
      variables: {
        user_id: context.user._id,
        base64list,
      },
      onCompleted: ({ importPDF }) => {
        const paperList = importPDF.response.map((bookmark) => {
          return {
            collection: bookmark.id_collection,
            id_field: bookmark.id_field,
            id_type: bookmark.id_type,
            id_value: bookmark.id_value,
          };
        });

        paginatedSearch({
          fetchPolicy: 'network-only',
          variables: {
            paper_list: paperList,
          },
          onCompleted: ({ paginatedSearch }) => {
            if (paginatedSearch.status === 'success') {
              const updatedBookmarks = [];

              paginatedSearch.response.forEach((paper) => {
                paperList.forEach((meta) => {
                  const paperIdStr = paper.id_int?.toString() ?? '';

                  if (paperIdStr === meta.id_value) {
                    updatedBookmarks.push({
                      _id: paper._id,
                      abstract: paper.Content.Abstract,
                      abstract_parsed: paper.Content.Abstract_Parsed,
                      doi: paper.DOI,
                      id_int: paper.id_int,
                      title: paper.Title,
                      id_collection: meta.collection,
                      id_field: meta.id_field,
                      id_type: meta.id_type,
                      id_value: meta.id_value,
                    });
                  }
                });
              });

              setBackdrop(false);
              context.updateContext('loadingMessage', 'Imported library');
              context.updateContext('bookmarks', updatedBookmarks);
            } else {
              notify(
                'Could not add papers to library at this moment, try again later'
              );
              setBackdrop(false);
              setBackdropText('');
              return;
            }
          },
        });
      },
    });
  };

  const onSubmit = (data) => {
    setBackdrop(true);
    setModal(false);
    setBackdropText('Uploading paper');

    addBookmarkFromMetadata({
      variables: {
        username: context.user._id,
        ...data,
      },
      onCompleted: ({ addBookmarkFromMetadata }) => {
        if (addBookmarkFromMetadata.status === 'success') {
          const paperList = addBookmarkFromMetadata.response.bookmarks.map(
            (bookmark) => ({
              collection: bookmark.id_collection,
              id_field: bookmark.id_field,
              id_type: bookmark.id_type,
              id_value: bookmark.id_value,
            })
          );

          paginatedSearch({
            variables: {
              paper_list: paperList,
            },
            onCompleted: ({ paginatedSearch }) => {
              if (paginatedSearch.status === 'success') {
                const updatedBookmarks = [];

                paginatedSearch.response.forEach((paper) => {
                  paperList.forEach((meta) => {
                    if (paper.id_int != null && paper.id_int.toString() === meta.id_value) {
                      updatedBookmarks.push({
                        _id: paper._id,
                        abstract: paper.Content.Abstract,
                        abstract_parsed: paper.Content.Abstract_Parsed,
                        doi: paper.DOI,
                        id_int: paper.id_int,
                        title: paper.Title,
                        id_collection: meta.collection,
                        id_field: meta.id_field,
                        id_type: meta.id_type,
                        id_value: meta.id_value,
                      });
                    }
                  });
                });

                context.updateContext('bookmarks', updatedBookmarks);
                setBackdropText('Uploaded paper');
                setBackdrop(false);
                context.updateContext('togglePanel', false);
                context.updateContext('searchLibrary', true);
              } else {
                notify('Could not add metadata item to library at this moment, try again later');
              }
            },
          });
        } else {
          notify(addBookmarkFromMetadata.message);
          setBackdrop(false);
          setBackdropText('');
        }
      },
    });
  };

  const handlePDFUpload = async (e) => {
    setBackdrop(true);
    setBackdropText('Uploading papers, please wait...');

    try {
      const files = e.target.files;
      const base64list = [];

      for (const file of files) {
        // Ignore non-PDF files and files over 50 MB
        if (!file.name.match(/.(pdf)$/i)) {
          notify('Only PDF files are allowed');
          setBackdrop(false);
          setBackdropText('');
          return;
        }
        if (bytesToMegaBytes(file.size) > 50) {
          notify('Maximum PDF size of 50 MB exceeded');
          setBackdrop(false);
          setBackdropText('');
          return;
        }

        let base64File = await getBase64(file);
        base64File = base64File.replace('data:application/pdf;base64,', '');
        base64list.push(base64File);
      }

      importFiles(base64list);
    } catch (err) {
      notify(err.message);
      setBackdrop(false);
    }
  };

  return (
    <>
      <GlobalModal
        isOpen={modal}
        setOpen={setModal}
        heading={'Enter paper metadata manually'}
        subheading={''}
      >
        <PaperForm onSubmit={onSubmit} />
      </GlobalModal>

      <input
        type="file"
        ref={importLibraryPDF}
        style={{ display: 'none' }}
        multiple
        accept="application/pdf"
        onChange={handlePDFUpload}
      />

      <Button
        size="small"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          border: 'none',
          width: '100%',
          textTransform: 'none',
        }}
        onClick={handleMenuClick}
      >
        <FileUploadOutlinedIcon fontSize="small" sx={{ marginRight: '8px' }} />
        <Typography variant="body1">Add paper to library from</Typography>
        <ArrowDropDownIcon />
      </Button>
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setModal(true);
          }}
        >
        metadata
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            importLibraryPDF.current.click();
          }}
        >
        .PDF
        </MenuItem>
      </Menu>
    </>
  );
};

export default AddPaperToLibrary;
