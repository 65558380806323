// Libraries
import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';

const Problem = () => (
  <Stack
    direction="column"
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2vw',
      width: '100%',
      height: 'auto',
      boxSizing: 'border-box',
      marginBottom: '50px',
      bgcolor: 'background.paper',
    }}
  >
    <Typography
      variant="h4"
      sx={{
        marginBottom: '20px',
        fontWeight: 'bold',
        maxWidth: '850px',
      }}
    >
      We see the challenges
    </Typography>
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      sx={{
        paddingLeft: '2vw',
        paddingRight: '2vw',
        marginTop: '20px',
        height: 'auto',
        boxSizing: 'border-box',
        marginBottom: '50px',
      }}
    >
      <Box>
        <FileCopyOutlinedIcon
          sx={{
            marginBottom: '20px',
            fontSize: '48px',
          }}
        />
        <Typography
          sx={{
            maxWidth: '350px',
          }}
        >
          The literature grows quickly and is becoming more multidisciplinary.
          Keeping up with the latest relevant research is challenging.
        </Typography>
      </Box>
      <Box>
        <AccessTimeOutlinedIcon
          sx={{
            marginBottom: '20px',
            fontSize: '48px',
          }}
        />
        <Typography
          sx={{
            maxWidth: '350px',
          }}
        >
          Research papers are long texts that deal with complex topics
          and reading them carefully takes a lot of time.
        </Typography>
      </Box>
      <Box>
        <TimelineOutlinedIcon
          sx={{
            marginBottom: '20px',
            fontSize: '48px',
          }}
        />
        <Typography
          sx={{
            maxWidth: '350px',
          }}
        >
          Existing tools for literature discovery, paper reading, and
          note taking are on separate platforms, which makes using them 
          together complicated.
        </Typography>
      </Box>
    </Stack>
    <Stack
      direction={'column'}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '50px',
        margin: '20px',
        borderRadius: '5px',
        boxSizing: 'border-box',
        background: '#265ed5',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: '20px',
          fontWeight: 'bold',
          maxWidth: '850px',
          color: 'white',
        }}
      >
        Endoc is our solution
      </Typography>
      <Typography
        variant="h6"
        sx={{
          maxWidth: '850px',
          color: 'white',
        }}
      >
        An all-in-one platform for note taking, literature discovery, and paper reading. Powered
        by state-of-the-art artificial intelligence (AI).
      </Typography>
    </Stack>
  </Stack>
);

export default Problem;
